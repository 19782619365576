import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [count, setCount] = useState(0);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Проверяем наличие объекта Telegram WebApp
    if (window.Telegram && window.Telegram.WebApp) {
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

      if (initDataUnsafe && initDataUnsafe.user) {
        // Получаем user_id из данных Telegram WebApp
        const userIdFromTelegram = initDataUnsafe.user.id;
        setUserId(userIdFromTelegram);
      }
    }
  }, []);

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Clicker</h1>
        {userId && <p>Ваш id: {userId}</p>}
        <p>You have clicked the button {count} times.</p>
        <button onClick={handleClick}>Click me</button>
      </header>
    </div>
  );
}

export default App;
